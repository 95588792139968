import { useQuery } from "@tanstack/react-query";
import { StrategyMenu } from "src/models/step-type";
import { getSetUpTypeList } from "src/services/setup-type";

export const useGetSetupTypeList = () => {
  return useQuery<StrategyMenu[], Error>({
    queryKey: ["setup_type_list"],
    queryFn: async () => {
      const res = await getSetUpTypeList();

      if (!res) throw new Error("Setup Type not found");

      return res;
    },
    staleTime: 1000 * 60 * 2,
  });
};
