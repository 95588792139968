import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

const GoogleCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const access_token = searchParams.get("access_token");
  const refresh_token = searchParams.get("refresh_token");

  useEffect(() => {
    if (access_token && refresh_token) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);

      navigate("/play-charts");
    }
  }, [access_token, refresh_token]);

  return (
    <div>
      <h1>Google Callback</h1>
    </div>
  );
};

export default GoogleCallback;
