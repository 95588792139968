import { handleGlobalError } from "src/lib/utils";
import { IChartsHistoryResponse, IPlayChart } from "src/models/play-charts";

import api from "../api";

export const getChartByStrategyId = async (strategyId: string) => {
  try {
    const res = await api.get(`/charts/${strategyId}/`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getPlayChartBySetUpTypeId = async (
  setUpTypeId: string,
  previousChartId?: string,
): Promise<IPlayChart | undefined> => {
  try {
    const res = await api.get(
      `/play-chart/${setUpTypeId}/play${previousChartId ? `?previous_chart_id=${previousChartId}` : ""}`,
    );
    return res.data;
  } catch (error) {
    return undefined;
  }
};

export const checkPlayChartBySetUpTypeId = async (
  setUpTypeId: string,
  chatId: string,
  userChoose: string,
  userExplanation: string,
  goodRate: string,
) => {
  try {
    const res = await api.post(`/play-chart/${setUpTypeId}`, {
      chart_id: chatId,
      user_choice: userChoose,
      user_explanation: userExplanation,
      good_rate: goodRate,
    });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getChartsHistoryBySlug = async (
  stepUpTypeId: string,
  skip?: number,
): Promise<IChartsHistoryResponse | undefined> => {
  try {
    const res = await api.get(`/chart-history/${stepUpTypeId}?limit=4${skip ? `&skip=${skip}` : ""}`);
    return res.data?.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getChartHistoriesBySetupType = async (
  setupTypeId: string,
  skip: number,
  params?: Partial<{ good_rates: string }>,
): Promise<IChartsHistoryResponse | undefined> => {
  try {
    const queryParams = new URLSearchParams({
      limit: "1",
      skip: skip.toString(),
      ...(params?.good_rates && { good_rates: params.good_rates }),
    });

    const res = await api.get(`/chart-history/${setupTypeId}?${queryParams}`);
    return res.data?.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};
