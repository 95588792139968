import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useGetSetupTypeList } from "src/hooks";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/components/shadcn/Card";

const EXAMPLE_DATA = [
  {
    key: "2",
    description:
      "Not up 2 days in a row. A small up day before the breakout is fine",
  },
  {
    key: "L",
    description: "Linearity of prior move",
  },
  {
    key: "Y",
    description:
      "Young trend: 1st to 3rd breakout. As trend ages, risk of failure increases",
  },
  {
    key: "N",
    description: "Narrow range day or negative day pre-breakout",
  },
  {
    key: "C",
    description: "Consolidation is shallow and compact with low volume",
  },
  {
    key: "H",
    description: "Close near high of the day",
  },
];

const SetupDefinitionDetail = () => {
  const { id } = useParams();
  const { data } = useGetSetupTypeList();

  // memos
  const currentSetupType = useMemo(() => {
    return data?.find((item) => item.id === id) || null;
  }, [data, id]);

  console.log(currentSetupType);
  return (
    <>
      <div>
        <h1 className="text-[34px] font-bold leading-10">Setup Definition</h1>
      </div>

      <div className="flex items-center justify-between pb-4 pt-2">
        <div className="space-y-2">
          <h1 className="text-lg font-semibold leading-7 text-slate-700">
            Continuation Setup with 2LYNCH Guide
          </h1>
        </div>
      </div>

      <Card className="px-9 py-11">
        <CardHeader className="hidden">
          <CardTitle>Setup Definition</CardTitle>
          <CardDescription>Description</CardDescription>
        </CardHeader>
        <CardContent className="p-0">
          <div className="">
            {EXAMPLE_DATA.map((data) => (
              <div
                key={data.key}
                className="flex items-center gap-4"
              >
                <p className="shrink-0 text-[32px] font-black text-slate-700">
                  {data.key}
                </p>
                <p className="text-sm font-medium text-slate-700">
                  {data.description}
                </p>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default SetupDefinitionDetail;
