import { useEffect, useRef, memo } from "react";

import { IChart } from "src/models/play-charts";
import { createDataFeed } from "src/data/datafeed";
import { widget, ChartingLibraryWidgetOptions, ResolutionString } from "src/charting_library";

import moment from "moment";

import "./index.css";

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
  load_last_chart: ChartingLibraryWidgetOptions["load_last_chart"];
  debug: ChartingLibraryWidgetOptions["debug"];
}

interface Props {
  currentData: IChart[];
}

const CustomTradingView: React.FC<Props> = ({ currentData }) => {
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const defaultProps: Omit<ChartContainerProps, "container"> = {
    symbol: "AAPL 112233",
    interval: "1D" as ResolutionString,
    datafeedUrl: "https://demo_feed.tradingview.com",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    load_last_chart: true,
    debug: true,
  };

  useEffect(() => {
    if (!currentData) return;

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      datafeed: createDataFeed(currentData),
      interval: defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      locale: "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_indicators",
        "header_compare",
        "header_symbol_search",
        "header_resolutions",
      ],
      enabled_features: ["study_templates"],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: "dark",
      overrides: {
        "paneProperties.legendProperties.showSeriesTitle": false,
        "mainSeriesProperties.candleStyle.upColor": "#0EA5E9",
        "mainSeriesProperties.candleStyle.borderUpColor": "#0EA5E9",
        "mainSeriesProperties.candleStyle.downColor": "#D946EF",
        "mainSeriesProperties.candleStyle.borderDownColor": "#D946EF",
        "mainSeriesProperties.candleStyle.wickUpColor": "#0EA5E9",
        "mainSeriesProperties.candleStyle.wickDownColor": "#D946EF",
      },
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      const volumeStudyId = tvWidget
        .activeChart()
        .getAllStudies()
        .find((x) => x.name === "Volume")?.id;

      if (volumeStudyId) {
        const volume = tvWidget.activeChart().getStudyById(volumeStudyId);

        if (volume) {
          volume.applyOverrides({
            "volume.color.0": "#D946EF",
            "volume.color.1": "#0EA5E9",
            "timeScale.rightOffset": 0, // Aligns the end of the chart
            "timeScale.barSpacing": 15,
          });
        }
      }

      const firstDataPoint = currentData[0];
      const lastDataPoint = currentData[currentData.length - 1];

      let from = 0;
      let to = 0;

      if (firstDataPoint && lastDataPoint) {
        const firstTimestamp = moment(firstDataPoint.date).subtract(1, "day").valueOf();
        const lastTimestamp = moment(lastDataPoint.date).add(2, "day").valueOf();
        console.log(firstDataPoint.date, firstTimestamp);
        console.log(lastDataPoint.date, lastTimestamp);

        from = Math.floor(firstTimestamp / 1000);
        to = Math.floor(lastTimestamp / 1000);
      }

      const result = tvWidget
        .chart()
        .setVisibleRange({
          from,
        })
        .then(() => console.log("New visible range is applied"))
        .catch((error) => console.log(error));
    });

    return () => {
      tvWidget.remove();
    };
  }, [currentData]);

  return (
    <div
      ref={chartContainerRef}
      className="TVChartContainer w-full rounded-md overflow-hidden"
    />
  );
};

export default memo(CustomTradingView);
