import { Mail } from "lucide-react";
import { useState } from "react";

import { Button } from "../shadcn/Button";
import { loginGoogle } from "src/services/auth";

const GoogleLoginButton = () => {
  // states
  const [loading, setLoading] = useState(false);

  // functions
  const handleLogin = async () => {
    setLoading(true);
    const res = await loginGoogle();
    setLoading(false);

    console.log(res);
    if (res?.auth_url) {
      window.location.href = res.auth_url;
    }
  };

  return (
    <Button
      disabled={loading}
      variant="outline"
      className="w-full bg-transparent border-black"
      onClick={handleLogin}
    >
      <Mail />
      <span className="text-black">Sign In with Gmail</span>
    </Button>
  );
};

export default GoogleLoginButton;
