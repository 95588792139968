import { z } from "zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

import { Input } from "src/components/shadcn/Input";
import { login } from "src/services/auth";
import { Button } from "src/components/shadcn/Button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/components/shadcn/Card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/components/shadcn/Form";

import GoogleLoginButton from "src/components/common/GoogleLoginButton";

const formSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
  password: z.string().min(1, {
    message: "Password is required.",
  }),
});

const LoginPage = () => {
  const navigate = useNavigate();

  // state
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // functions
  const handleLogin = async (email: string, password: string) => {
    setIsLoading(true);
    const res = await login(email, password);
    setIsLoading(false);

    if (res) {
      localStorage.setItem("access_token", res.access_token);
      localStorage.setItem("refresh_token", res.refresh_token);

      navigate("/play-charts");
    }
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    handleLogin(data.email, data.password);
  };

  return (
    <div className="grow flex flex-col items-center justify-center">
      <div className="max-w-[300px] mx-auto">
        <img
          src="/images/main-logo.png"
          alt="VSA trading"
          className="w-full h-auto"
        />
      </div>

      <Card className="w-full max-w-80 mx-auto rounded-md border border-slate-200">
        <CardHeader className="hidden">
          <CardTitle>Login</CardTitle>
          <CardDescription>Login to your account</CardDescription>
        </CardHeader>
        <CardContent className="p-4">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-2"
            >
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="text-xs md:text-sm font-medium leading-5 text-[#0F172A]">
                        Your Email
                      </FormLabel>
                      <FormControl className="mt-0">
                        <Input
                          placeholder="Enter your email"
                          {...field}
                          className="mt-0 w-full"
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="text-xs md:text-sm font-medium leading-5 text-[#0F172A]">
                        Your Password
                      </FormLabel>
                      <FormControl className="mt-0">
                        <Input
                          type="password"
                          placeholder="Enter your password"
                          {...field}
                          className="mt-0 w-full"
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />
              </div>
              <Button
                type="submit"
                disabled={isLoading}
                className="w-full bg-gradient-btn"
              >
                {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
                <p>Login</p>
              </Button>
            </form>
          </Form>

          <div className="mt-6 space-y-6">
            <GoogleLoginButton />
            <div className="w-full h-[1px] bg-[#d9d9d9]" />
            <Button
              variant="outline"
              className="w-full bg-transparent border-black"
              onClick={() => {
                navigate("/signup-with-email");
              }}
            >
              Sign Up
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginPage;
