import { z } from "zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "../shadcn/Input";
import { Button } from "../shadcn/Button";
import { magicLink } from "src/services/auth";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../shadcn/Form";

const formSchema = z.object({
  email: z.string().email({
    message: "Email is required.",
  }),
});

const SendEmail = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  // states
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    console.log(data);

    setIsLoading(true);
    const response = await magicLink(data.email);
    setIsLoading(false);

    if (response) {
      console.log(response);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-full space-y-2"
      >
        <div className="space-y-2">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="space-y-0">
                <FormLabel className="text-xs md:text-sm font-medium leading-5 text-[#0F172A]">Your Email</FormLabel>
                <FormControl className="mt-0">
                  <Input
                    placeholder="Enter your email"
                    {...field}
                    className="mt-0 w-full"
                  />
                </FormControl>
                <FormMessage className="text-xs" />
              </FormItem>
            )}
          />
        </div>
        <Button
          type="submit"
          disabled={isLoading}
          className="w-full bg-gradient-btn"
        >
          {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
          <p>Send Email</p>
        </Button>
      </form>
    </Form>
  );
};

export default SendEmail;
