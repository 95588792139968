import { memo } from "react";

import { ZapIcon } from "src/icons";

interface Props {
  title: string;
  description: string;
}

const TittlePage: React.FC<Props> = ({ title, description }) => {
  return (
    <div className="main-shadow rounded-md flex items-start gap-4 border border-[#E5E7EB] p-4">
      <div className="shrink-0">
        <ZapIcon />
      </div>
      <div>
        <p className="text-sm leading-5 font-semibold text-[#0F172A]">
          {title}
        </p>
        <p className="text-sm leading-6 font-normal text-[#0F172A]">
          {description}
        </p>
      </div>
    </div>
  );
};

export default memo(TittlePage);
