import { z } from "zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button } from "src/components/shadcn/Button";
import { Textarea } from "src/components/shadcn/Textarea";
import { SelectStar } from "src/components/blocks";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/shadcn/Form";

const formSchema = z.object({
  explanation: z.string().min(1, {
    message: "Explanation is required.",
  }),
});

const ExplanationDetail = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      explanation: "",
    },
  });

  // states
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleSubmit = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setIsLoading(false);
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    console.log(data);
    handleSubmit();
  };

  return (
    <>
      <div className="mb-4">
        <h1 className="text-[34px] font-bold leading-10">Explanation</h1>
      </div>

      <SelectStar />

      <div className="mt-4">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full space-y-4"
          >
            <FormField
              control={form.control}
              name="explanation"
              render={({ field }) => (
                <FormItem className="space-y-0">
                  <FormControl className="mt-0">
                    <Textarea
                      placeholder="Saving your explanation to chart history helps you track progress, spot patterns, and compare your reasoning with GrowthSight."
                      {...field}
                      className="mt-0 w-full text-sm"
                      rows={15}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full md:w-fit bg-gradient-btn gap-1"
              disabled={form.getValues("explanation") === ""}
            >
              {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>Submit</p>
            </Button>
          </form>
        </Form>
      </div>
    </>
  );
};

export default ExplanationDetail;
