import { Dispatch, SetStateAction } from "react";

import { useGetSetupTypeList } from "src/hooks";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/Select";

interface Props {
  currentValue?: string;
  onSetCurrentValue?: Dispatch<SetStateAction<string>>;
}

const FilterChartBySetupType: React.FC<Props> = ({
  currentValue,
  onSetCurrentValue,
}) => {
  const { data } = useGetSetupTypeList();

  // functions
  const handleOnChange = (value: string) => {
    onSetCurrentValue?.(value ?? "");
  };

  return (
    <div className="py-2 space-y-2 select-setup-type">
      <p className="text-sm leading-6 font-bold text-slate-700">
        Filter charts by:
      </p>

      <Select
        value={currentValue ?? ""}
        onValueChange={handleOnChange}
      >
        <SelectTrigger className="w-full bg-slate-100 border-transparent justify-center gap-1 focus:ring-0 focus:ring-offset-0">
          <SelectValue
            placeholder={
              <span className="text-sm text-slate-900 font-bold leading-6">
                Continuation Breakout
              </span>
            }
            className=""
          />
        </SelectTrigger>
        <SelectContent className="p-2">
          {(data ?? []).map((item) => (
            <SelectItem
              key={item.id}
              value={item.id}
              className="text-sm"
            >
              {item.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default FilterChartBySetupType;
