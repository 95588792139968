import { Loader2 } from "lucide-react";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { activateAccount } from "src/services/auth";

const Callback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("access_token");

  // states
  const [isActivating, setIsActivating] = useState(true);

  // functions
  const handleActivateAccount = async (token: string) => {
    const res = await activateAccount(token);
    setIsActivating(false);

    if (res) {
      toast.success("Sign up successful!");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (token) {
      handleActivateAccount(token);
    }
  }, [token]);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-full flex items-center justify-center gap-1">
        <span className="text-sm font-medium leading-6">Activating your account ...</span>
        <Loader2 className="animate-spin size-5 font-semibold" />
      </div>
    </div>
  );
};

export default Callback;
