import { handleGlobalError } from "src/lib/utils";
import api, { axiosClient } from "../api";
import { ILoginResponse } from "src/models";

export const refreshToken = async (token: string) => {
  try {
    const res = await api.post(`/auth/refresh?refresh_token=${token}`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const magicLink = async (email: string) => {
  try {
    const res = await api.post("/auth/magic-link", { email });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const login = async (email: string, password: string): Promise<ILoginResponse | undefined> => {
  try {
    const res = await axiosClient.post("/auth/token", { email, password });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const register = async (email: string, password: string) => {
  try {
    const res = await api.post("/auth/register", { email, password });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const activateAccount = async (access_token: string) => {
  try {
    const res = await api.post("/auth/activate", { access_token });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const loginGoogle = async () => {
  try {
    const res = await api.get("/auth/login/google");
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};
