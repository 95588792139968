import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { clsx, type ClassValue } from "clsx";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleGlobalError = (err: unknown) => {
  if (err && typeof err === "object") {
    console.log(err);
    const response = (err as { response: { status: number; data: { detail?: string; message?: string } } }).response;

    if (response?.status === 401) {
      toast.error(response?.data?.detail || "Unauthorized");
      return;
    }

    toast.error(
      response?.data?.message ||
        "There seems to be an issue with getting responses. Apologize for the inconvenience, please try again.",
    );

    return;
  }

  toast.error("There seems to be an issue with getting responses. Apologize for the inconvenience, please try again.");
};

export const checkIsMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};
