import { ArrowLeftIcon } from "src/icons";
import { Button } from "../shadcn/Button";

interface Props {
  onClick?: () => void;
}

const ButtonBack: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      variant="outline"
      className="border-black bg-gray-50"
      onClick={() => {
        onClick?.();
      }}
    >
      <ArrowLeftIcon />
      <span className="text-sm font-medium leading-6">Back</span>
    </Button>
  );
};

export default ButtonBack;
