import { Star } from "lucide-react";
import { Dispatch, SetStateAction } from "react";

import { cn } from "src/lib/utils";

interface Props {
  currentStar?: number;
  setCurrentStar?: Dispatch<SetStateAction<number | null>>;
}

const CustomButton = ({
  children,
  className,
  isActive,
  ...props
}: {
  children: React.ReactNode;
  className: string;
  isActive: boolean;
} & React.ComponentProps<"button">) => {
  return (
    <button
      className={cn(
        "justify-center text-white whitespace-nowrap rounded-md text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 flex items-center border-2 border-transparent gap-2 transition-all duration-300 h-10 px-4 py-2",
        className,
        isActive && "bg-white text-black border-black",
      )}
      {...props}
    >
      {children}
    </button>
  );
};

const SelectStar = ({ currentStar, setCurrentStar }: Props) => {
  const isActive = (star: number) => {
    return currentStar === star;
  };

  return (
    <div className="flex items-center flex-wrap gap-2">
      <CustomButton
        className="bg-fuchsia-500"
        isActive={isActive(0)}
        onClick={() => setCurrentStar?.(0)}
      >
        Bad
      </CustomButton>
      <CustomButton
        className="bg-sky-400"
        isActive={isActive(1)}
        onClick={() => setCurrentStar?.(1)}
      >
        <Star />
        <span>1</span>
      </CustomButton>
      <CustomButton
        className="bg-sky-500"
        isActive={isActive(2)}
        onClick={() => setCurrentStar?.(2)}
      >
        <Star />
        <span>2</span>
      </CustomButton>
      <CustomButton
        className="bg-sky-600"
        isActive={isActive(3)}
        onClick={() => setCurrentStar?.(3)}
      >
        <Star />
        <span>3</span>
      </CustomButton>
      <CustomButton
        className="bg-sky-700"
        isActive={isActive(4)}
        onClick={() => setCurrentStar?.(4)}
      >
        <Star />
        <span>4</span>
      </CustomButton>
      <CustomButton
        className="bg-sky-800"
        isActive={isActive(5)}
        onClick={() => setCurrentStar?.(5)}
      >
        <Star />
        <span>5</span>
      </CustomButton>
    </div>
  );
};

export default SelectStar;
