import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ICheckPlayChart } from "src/models/play-charts";
import { Dispatch, SetStateAction, useState } from "react";

import { Button } from "../shadcn/Button";
import { Textarea } from "../shadcn/Textarea";
import { checkPlayChartBySetUpTypeId } from "src/services/charts";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../shadcn/Form";

interface Props {
  setupTypeId: string;
  chartId: string;
  star: number | null;
  setCheckPlayChartData: Dispatch<SetStateAction<ICheckPlayChart | null>>;
  onCallBack?: () => void;
}

const formSchema = z.object({
  explanation: z.string(),
});

const SendUserAnswer: React.FC<Props> = ({ setupTypeId, chartId, star, setCheckPlayChartData, onCallBack }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      explanation: "",
    },
  });

  // states
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    if (star === null) {
      toast.error("Please select a star before submitting.");
      return;
    }

    const userChoice = star === 0 ? "Yes" : "No";
    const goodRate = star === 0 ? "-1" : `${star}`;

    setIsLoading(true);
    const res = await checkPlayChartBySetUpTypeId(setupTypeId, chartId, userChoice, data.explanation, goodRate);
    setIsLoading(false);

    if (res) {
      setCheckPlayChartData({ ...res });
      onCallBack?.();
    }
  };

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full space-y-4"
        >
          <FormField
            control={form.control}
            name="explanation"
            render={({ field }) => (
              <FormItem className="space-y-0">
                <FormControl className="mt-0">
                  <Textarea
                    placeholder="Saving your explanation to chart history helps you track progress, spot patterns, and compare your reasoning with GrowthSight."
                    {...field}
                    className="mt-0 w-full text-sm"
                    rows={10}
                  />
                </FormControl>
                <FormMessage className="text-xs" />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="w-full bg-gradient-btn gap-1"
            disabled={star === null}
          >
            {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
            <p>Submit</p>
          </Button>
        </form>
      </Form>
    </>
  );
};

export default SendUserAnswer;
