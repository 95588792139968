import { z } from "zod";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { Loader2, Mail } from "lucide-react";

import { Input } from "src/components/shadcn/Input";
import { Button } from "src/components/shadcn/Button";
import { register } from "src/services/auth";
import { ButtonBack } from "src/components/common";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/components/shadcn/Card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/components/shadcn/Form";

const formSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
  password: z.string().min(1, {
    message: "Password is required.",
  }),
});

const SignUpWithEmail = () => {
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // functions
  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    const res = await register(data.email, data.password);
    setIsLoading(false);

    if (res) {
      localStorage.setItem("access_token", res.access_token);
      localStorage.setItem("refresh_token", res.refresh_token);

      navigate("/play-charts");
    }
  };

  return (
    <>
      <div>
        <ButtonBack onClick={() => navigate("/login")} />
      </div>
      <div className="grow flex flex-col items-center justify-center">
        <div className="max-w-[300px] mx-auto">
          <img
            src="/images/main-logo.png"
            alt="VSA trading"
            className="w-full h-auto"
          />
        </div>

        <Card className="w-full max-w-80 mx-auto rounded-md border border-slate-200">
          <CardHeader className="hidden">
            <CardTitle>Login</CardTitle>
            <CardDescription>Login to your account</CardDescription>
          </CardHeader>
          <CardContent className="p-4">
            {!isSuccess ? (
              <>
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="w-full space-y-2"
                  >
                    <div className="space-y-2">
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem className="space-y-0">
                            <FormLabel className="text-xs md:text-sm font-medium leading-5 text-[#0F172A]">
                              Your Email
                            </FormLabel>
                            <FormControl className="mt-0">
                              <Input
                                placeholder=""
                                {...field}
                                className="mt-0 w-full"
                              />
                            </FormControl>
                            <FormMessage className="text-xs" />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                          <FormItem className="space-y-0">
                            <FormLabel className="text-xs md:text-sm font-medium leading-5 text-[#0F172A]">
                              Your Password
                            </FormLabel>
                            <FormControl className="mt-0">
                              <Input
                                type="password"
                                placeholder=""
                                {...field}
                                className="mt-0 w-full"
                              />
                            </FormControl>
                            <FormMessage className="text-xs" />
                          </FormItem>
                        )}
                      />
                    </div>
                    <Button
                      type="submit"
                      disabled={isLoading}
                      className="w-full bg-gradient-btn"
                    >
                      {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
                      <p>Sign Up</p>
                    </Button>
                  </form>
                </Form>

                <div className="mt-6 space-y-6">
                  <div className="w-full h-[1px] bg-[#d9d9d9]" />
                  <Button
                    variant="outline"
                    className="w-full bg-transparent border-black"
                  >
                    <Mail />
                    <span className="text-black">Sign In with Gmail</span>
                  </Button>
                </div>
              </>
            ) : (
              <div className="max-w-[270px]">
                <p className="text-lg leading-7 font-semibold text-slate-700 text-center">
                  Check your inbox to complete activation
                </p>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default SignUpWithEmail;
